<template>
  <div class="about-naga">
      <div class="container">
          <div class="about-naga-content">
              <div class="about-naga-header">
                  <h3>About Naga markets</h3>
              </div>

              <div class="about-naga-p">
                  <p>Since 2015, award-winning Naga markets has been offering a world class offering to traders of all levels. They offer a huge selection of over 500 assets across cryptos, stocks, Indices, Forex and Commmodities in a CySeC regulated environment. Their trading conditions are generous and they boast all the latest technology including the MT4, MT5, proprietary WebTrader platform and mobile app. NAGA Group AG IPO’d in 2017 and lead the world’s 18th largest token sale, with over 63,000 subscribers, reaching >$50 million just a couple of months after the company went public on the Frankfurt Stock Exchange. This all goes to explain why this relatively new broker already boasts over 500,000 accounts and growing.</p>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
    .about-naga-header h3 {
        text-align: center;
        font-size: 38px;
        margin-top: 50px;
        margin-bottom: 10px;
    }

    .about-naga-p {
        max-width: 1364px;
        width: 100%;
        margin: 0 auto;
    }
    .about-naga-p p {
        text-align: center;
        font-size: 21px;
    }

    @media(max-width:1024px){
        .about-naga-header h3 {
        font-size: 26px;
    }

       .about-naga-p p {
        text-align: center;
        font-size: 18px;
    }

    }

    @media(max-width:768px){
        .about-naga-header h3 {
            margin-top: 30px;
        }
    }
    @media(max-width:1024px){
    

       .about-naga-p p {
        text-align: center;
        font-size: 16px;
    }

    }
</style>